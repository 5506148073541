
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { IItem, IItemUpdate, ILabel, ITargetLabel, IRecommendationUpdate } from "@/interfaces";
import { api } from "@/api";
import { readToken, readWorkspace } from "@/store/main/getters";
import { readLabels, readLabelsByIds, readLabelsByName, readModel } from "@/store/model/getters";

import { dispatchAddItemLabels } from "@/store/model/actions";

import TextHighlight from "vue-text-highlight";
import { parse } from "path";

@Component({
  components: {
    TextHighlight,
  },
})
export default class ItemPreviewConfusionMatrix extends Vue {
  // internal properties
  @Prop({ default: false }) public item!: IItem;
  @Prop({ default: false }) public labels?: ILabel[];
  @Prop({ default: true }) public flat!: boolean;
  @Prop({ default: true }) public single!: boolean;
  @Prop({ default: true }) public usepreds!: boolean;
  @Prop({}) public highlights!: string[];
  public hide = false;
  public loading: boolean = false;
  public searchTerm = "";
  public updateReason: any = null;
  /**
   * upload event...
   */

  get hideThis() {
    if (this.hide) {
      return "background-color: rgb(255 198 198);";
    }
    return "";
  }

  get levels() {
    if (this.single === true) {
      return 0.001;
    } else {
      return 50;
    }
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  public async changeLabel(changeToLabelId: number, item: any) {
    console.log("change label", changeToLabelId, item);
    this.loading = true;
    //this is recommendation recommendation_type='single' recommendation='correction' target=Target(label_container=115, label=1727) items=[UpdateUserLabel(id='852f780907bb41c', store_id='1_76fa53b430a245448ef469415688823d', user_labels=[UserLabel(label_container=115, label=1726)])]
    this.updateReason = null;
    const label: ITargetLabel = {
      label_container: this.model!.label_containers[0].id,
      label: changeToLabelId,
    };

    let recommendation = "correction";
    if (item.user_labels[0].label === -1) {
      recommendation = "random";
    }

    const updateItem: IItemUpdate = {
      id: item.id,
      store_id: item.store_id,
      user_labels: [label],
    };

    const update: IRecommendationUpdate = {
      recommendation_type: "single",
      recommendation: recommendation,
      target: label,
      items: [updateItem],
    };

    await dispatchAddItemLabels(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      labels: update,
    }).then(
      (r) => {
        console.log(r);
        this.loading = false;
      },
      (reason) => {
        this.loading = false;
        console.log(reason);
        this.updateReason = "Something went wrong when updating the label";
      },
    );

    item.user_labels[0].label = changeToLabelId;
  }

  get sortedLabels() {
    if (this.labelsAndSkipped) {
      let sorted = this.labelsAndSkipped!.map((o) => ({ ...o }));
      sorted = sorted.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
      );
      if (sorted.length === 0) {
        sorted = this.labelsAndSkipped!.map((o) => ({ ...o }));
        sorted = this.searchCategories(this.searchTerm, sorted);
      }
      return sorted;
    }
    return this.labelsAndSkipped;
  }

  // Levenshtein distance function
  public levenshtein(a: string, b: string): number {
    const matrix = Array.from({ length: a.length + 1 }, () => new Array(b.length + 1).fill(0));

    for (let i = 0; i <= a.length; i++) {
      matrix[i][0] = i;
    }
    for (let j = 0; j <= b.length; j++) {
      matrix[0][j] = j;
    }
    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost,
        );
      }
    }

    return matrix[a.length][b.length];
  }

  // N-gram public
  public ngrams(s: string, n: number): Set<string> {
    const result = new Set<string>();

    for (let i = 0; i <= s.length - n; i++) {
      result.add(s.slice(i, i + n));
    }

    return result;
  }

  // Main search public
  public searchCategories(query: string, categories: ILabel[]): ILabel[] {
    const ngramSize = 3;
    const queryNgrams = this.ngrams(query.toLowerCase(), ngramSize);

    const scoredCategories = categories.map((category) => {
      const categoryNgrams = this.ngrams(category.name.toLowerCase(), ngramSize);
      const intersectionSize = [...queryNgrams].filter((x) => categoryNgrams.has(x)).length;
      const jaccardIndex =
        intersectionSize / (queryNgrams.size + categoryNgrams.size - intersectionSize);
      const levDist = this.levenshtein(query.toLowerCase(), category.name.toLowerCase());
      const score = jaccardIndex * (1 / (1 + levDist));
      return { ...category, score };
    });

    return scoredCategories.sort((a, b) => b.score - a.score);
  }

  get token() {
    return readToken(this.$store);
  }

  public openPage(item: any) {
    console.log(item);
    api
      .getConversationIdFromItemId(
        this.token,
        parseInt(this.$router.currentRoute.params.id, 10),
        this.item!.store_id,
        this.item!.id,
      )
      .then((response) => {
        console.log(response.data.conversation_id);
        window.open(
          `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${response.data.dataset_id}/dashboard/browse?conversation_id=${response.data.conversation_id}`,
          "_blank",
        );
      });
  }

  get labelsObj() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelsAndSkipped() {
    let labelsCopy = readLabels(this.$store)(+this.$router.currentRoute.params.id);
    const skipped: ILabel = {
      counter: 0,
      description: "Skip this example",
      id: -2,
      name: "skip",
      color: "#000000",
    };
    labelsCopy.push(skipped);
    return labelsCopy;
  }

  public labelObject(labelId) {
    const filteredLabel = this.labelsObj!.filter((labelItem) => labelItem.id === labelId);
    if (filteredLabel.length === 0) {
      if (labelId === -2) {
        return {
          name: "skipped",
          color: "#000000",
        };
      } else if (labelId === -1) {
        return {
          name: "unlabeled",
          color: "#E8E6E5",
        };
      } else if (labelId === 0) {
        return {
          name: "unlabeled",
          color: "#E8E6E5",
        };
      } else {
        return {
          name: "UNKNOWN LABEL",
          color: "#000000",
        };
      }
    }
    return {
      name: filteredLabel[0].name,
      color: filteredLabel[0].color,
    };
  }

  get labelsOrderedByPreds() {
    const orderedLabels: any[] = [];
    this.labels!.forEach((label) => {
      const labelExtend = JSON.parse(JSON.stringify(label));
      labelExtend.prediction = this.maxConfidence(label.id);
      orderedLabels.push(labelExtend);
    });
    return orderedLabels.slice().sort((a, b) => b.prediction - a.prediction);
  }

  public maxConfidence(labelId) {
    if (this.item!.hasOwnProperty("predictions") && this.item!.predictions.length !== 0) {
      const prediction = this.item!.predictions.filter((pred) => pred.label === labelId);
      if (prediction.length !== 0) {
        return prediction[0].prediction * 100;
      }
    }
    return 0;
  }

  public getLabelById(id) {
    const filteredItems = this.labels!.filter((label) => label.id === id);
    if (filteredItems.length > 0) {
      return filteredItems[0];
    }
    return 0;
  }
}
